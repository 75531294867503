<template>
  <section>
    <div :style="getStyle" v-if="data.properties.filed_content != 'Hide'">
      <h1
        v-if="
          data.heading_type == 'H1' &&
          data.properties &&
          data.properties.filed_content != 'Hide'
        "
        class="heading"
        :style="getFontStyle('H1')"
      >
        {{ label || data.label }}
      </h1>
      <h2
        v-if="
          data.heading_type == 'H2' &&
          data.properties &&
          data.properties.filed_content != 'Hide'
        "
        class="heading"
        :style="getFontStyle('H2')"
      >
        {{ label || data.label }}
      </h2>
      <h3
        v-if="
          data.heading_type == 'H3' &&
          data.properties &&
          data.properties.filed_content != 'Hide'
        "
        class="heading"
        :style="getFontStyle('H3')"
      >
        {{ label || data.label }}
      </h3>
      <h4
        v-if="
          data.heading_type == 'H4' &&
          data.properties &&
          data.properties.filed_content != 'Hide'
        "
        class="heading"
        :style="getFontStyle('H4')"
      >
        {{ label || data.label }}
      </h4>
      <h5
        v-if="
          data.heading_type == 'H5' &&
          data.properties &&
          data.properties.filed_content != 'Hide'
        "
        class="heading"
        :style="getFontStyle('H5')"
      >
        {{ label || data.label }}
      </h5>
      <h6
        v-if="
          data.heading_type == 'H6' &&
          data.properties &&
          data.properties.filed_content != 'Hide'
        "
        class="heading"
        :style="getFontStyle('H6')"
      >
        {{ label || data.label }}
      </h6>
      <span
        v-if="isActive && data.properties.filed_content != 'Hide'"
        class="setting-icon"
        @click="openSettings"
      >
        <i class="el-icon-s-tools" />
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "templates-formComponentsExecute-HeadingExecute",
  components: {},
  props: [
    "data",
    "label",
    "form",
    "hasLabel",
    "isActive",
    "colorFields",
    "isView",
    "fromEntityViews",
    "isFreeStyle",
  ],
  data() {
    return {
      validations: [],
      inputTextAlignmentValue: "left",
    };
  },
  mounted() {},
  computed: {
    getStyle() {
      const isMobile = window.innerWidth <= 768;
      const height = isMobile ? "auto" : this.data.height;
      const width = isMobile ? 300 : this.data.width;
      const overflowStyle = isMobile ? "overflow: hidden;" : "overflow: auto;";
      const wordWrapStyle = isMobile ? "word-wrap: break-word;" : "";
      if (this.isFreeStyle) {
        return (
          `height:${height}px;width:${width}px;${overflowStyle}${wordWrapStyle}` +
          (this.fromEntityViews ? `font-size:${(800 / 1200) * 18.72}px;` : "") +
          this.getElementStyle
        );
      }
      return (
        (this.fromEntityViews ? `font-size:${(800 / 1200) * 18.72}px;` : "") +
        this.getElementStyle
      );
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.style &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.style &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += ";text-align:" + this.inputTextAlignmentValue + ";";
      }
      return borderStyle;
    },
  },
  methods: {
    getFontStyle(type) {
      if (this.fromEntityViews) {
        const sizeMap = {
          H1: 32,
          H2: 24,
          H3: 18.72,
          H4: 16,
          H5: 13.28,
          H6: 10.72,
        };
        const baseSize = sizeMap[type];
        if (baseSize) {
          return `font-size:${(800 / 1200) * baseSize}px;`;
        }
      }
      return "";
    },
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignmentValue = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
    form: {
      handler() {},
    },
  },
};
</script>

<style lang="scss">
.heading {
  white-space: normal;
  word-wrap: break-word;
}
</style>
