<template>
  <div
    :style="getElementStyle"
    v-if="
      !loading &&
      data &&
      data.properties &&
      data.properties.filed_content != 'Hide'
    "
  >
    <el-row v-if="!data.properties.filed_content != 'Hide'">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
        ref="colRef"
        :style="computedStyles"
        class="bottom-space"
      >
          <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
          <label
            for="text"
            v-if="hasLabel && !data.properties.hideLabel"
            :style="getLabelStyles"
          >
            {{ label || data.label }}
          </label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col
        v-else
        :span="showLabel ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <span
          v-if="hasLabel"
          style="color: white; display: inline-block; height: 20px; opacity: 0"
        ></span>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>

      <el-col :span="showLabel && !isDefalutPos ? 12 : 24"  class="execute-view">
        <!-- <el-col :span="(showLabel && !isDefalutPos)?8:((data.description)?20:24)">
        <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
      </el-col>
      <el-col :span="(data.description)?4:0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
      </el-col> -->

        <div
          v-if="
            !isView &&
            data.properties &&
            data.properties.filed_content != 'Hide'
          "
        >
          <template v-if="data.inputType == 'PHONE'">
            <input
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :suffix-icon="data.properties.el_icon"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              @blur="validatePhone"
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />
          </template>
          <template v-if="data.inputType == 'CURRENCY'">
            <input
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :suffix-icon="data.properties.el_icon"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              @blur="validateCurrency"
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />
          </template>
          <template v-if="data.inputType == 'FAX'">
            <input
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :suffix-icon="data.properties.el_icon"
              @blur="validateFax"
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />
          </template>
          <template v-if="data.inputType == 'ZIP_CODE'">
            <input
              type="text"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :suffix-icon="data.properties.el_icon"
              @blur="validateZipCode"
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />
          </template>
          <template
            v-if="
              data.inputType == 'NUMBER' &&
              data.properties &&
              data.properties.number_type &&
              data.properties.number_type === 'PERCENTAGE'
            "
          >
            <!-- {{ form[this.data.key] }} -->
            <!--   :key="form[data.key]" -->
            <formulate-input
              v-model="form[data.key]"
              :placeholder="data.placeholder || data.label"
              type="percentage"
              :min="0"
              :max="100"
              :style="getStyle"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :suffix-icon="data.properties.el_icon"
              class="el-input__inner-"
              :class="{ 'disabled-field': checkReadonly() }"
              @applyFormRules="applyFormRules"
            />

            <!-- <percent-input
           :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly()"
            :integer-places="2"
            :decimal-places="2"
            :suffix=true 
            :style="getStyle"
            value="10"
            class="percentag_style"></percent-input> -->

            <!-- <div v-if="inputType =='number'">
            <el-input
              type="number"
              :readonly="true"
              v-if="isList"
              :style="getStyle"
              v-model="form[this.data.key]"
              :suffix-icon="data.properties.el_icon"
              @blur="validateNumber"
              :min="data.properties.min_value"
              :max="data.properties.max_value"
              :step="
                data.properties.step_value ? data.properties.step_value : 1
              "
              @change="applyFormRules"
            ></el-input>

           </div>
           <div v-else-if = "inputType == 'text'">
            <el-input
              type="text"
              :readonly="true"
              v-if="isList"
              :style="getStyle"
              v-model="form[this.data.key]"
              :suffix-icon="data.properties.el_icon"
              @change="applyFormRules"
            ></el-input>

           </div> -->

            <!-- 
            <el-input
              v-if="
                !isView && !isList && data.properties.filed_content != 'Hide'
              "
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="getStyle"
              :disabled="isFromDocument ? false : checkReadonly()"
              :suffix-icon="data.properties.el_icon"
              :readonly="readonly()"
              @blur="validateNumber"
              :min="data.properties.min_value"
              :max="data.properties.max_value"
              :step="
                data.properties.step_value ? data.properties.step_value : 1
              "
              @change="applyFormRules"
            ></el-input> -->
          </template>

          <template v-else-if="data.inputType == 'NUMBER'">
            <input
              type="number"
              :readonly="true"
              v-if="isList"
              v-model="form[this.data.key]"
              :suffix-icon="data.properties.el_icon"
              @blur="validateNumber"
              :min="data.properties.min_value"
              :max="data.properties.max_value"
              :step="
                data.properties.step_value ? data.properties.step_value : 1
              "
              @change="applyFormRules"
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />

            <input
              v-if="
                !isView &&
                !isList &&
                data.properties &&
                data.properties.filed_content != 'Hide'
              "
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :disabled="isFromDocument ? false : checkReadonly()"
              :suffix-icon="data.properties.el_icon"
              :readonly="readonly()"
              @blur="validateNumber"
              :min="data.properties.min_value"
              :max="data.properties.max_value"
              :step="
                data.properties.step_value ? data.properties.step_value : 1
              "
              @change="applyFormRules"
              class="nfe"
              :class="{
                'disabled-field': checkReadonly(),
                'highlighted-border': highlight,
              }"
            />
          </template>
          <template v-if="data.inputType == 'DECIMAL'">
            <input
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :disabled="isFromDocument ? false : checkReadonly()"
              :suffix-icon="data.properties.el_icon"
              :readonly="readonly()"
              @blur="validateDecimal"
              :min="data.properties.min_value"
              :max="data.properties.max_value"
              :step="
                data.properties.step_value ? data.properties.step_value : 1
              "
              :precision="
                data.properties.precision_value
                  ? data.properties.precision_value
                  : 2
              "
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />
          </template>
          <template v-if="data.inputType == 'PERCENT'">
            <input
              type="number"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :disabled="isFromDocument ? false : checkReadonly()"
              :suffix-icon="data.properties.el_icon"
              :readonly="readonly()"
              @blur="validateCurrency"
              class="nfe"
              :class="{ 'disabled-field': checkReadonly() }"
            />
          </template>
        </div>
        <p v-if="hasError" style="color: red; font-size: 14px">
          {{ errorMsg }}
        </p>
        <p
          v-if="
            isView &&
            data &&
            data.properties &&
            data.properties.filed_content != 'Hide'
          "
          :style="{ textAlign: inputTextAlignment }"
        >
          <template v-if="form[data.key] || !isNaN(form[data.key])">
            <span
              v-if="
                data.inputType == 'NUMBER' &&
                data.properties &&
                data.properties.number_type &&
                data.properties.number_type === 'PERCENTAGE'
              "
              >{{ form[`${data.key}`] }}%</span
            >
            <span v-else>
              {{ form[data.key] }}
            </span>
          </template>
          <template v-else>-</template>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import templateConstants from "../../../constants/lg-en/templates";
import { mapGetters } from "vuex";

import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "templates-formComponentsExecute-NumberExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "templateRules",
    "fieldsData",
    "checkIsDisabled",
    "profilePage",
    "colorFields",
    "highlight",
    "allFieldsData",
    "isIconRequired",
    "labelAlignments"
  ],
  async mounted() {
    this.loading = true;
    this.options = this.data.options;
    //this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      await this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.inputTextAlignment =
      this.data.styles && this.data.styles.inputTextAlignments
        ? this.data.styles.inputTextAlignments
        : "left";
    this.loading = false;
    this.isDefaultMappedFieldParent = (this.allFieldsData || []).find(e => e.default_mapped_field == this.data.key);
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
       if(this.labelAlignments || this.data?.styles?.labelAlignments){
        styles.justifyContent = this.labelAlignments || this.data?.styles?.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
    // inputTextAlignment() {
    // return this.data.styles && this.data.styles.inputTextAlignments
    //   ? this.data.styles.inputTextAlignments
    //   : 'left'; // Set a default value if not provided
    // },
  },
  data() {
    return {
      inputType: "number",
      isList: false,
      validations: [],
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      percentage: 99,
      inputTextAlignment: "right",
      previousValue: null,
      isDefaultMappedFieldParent : false
    };
  },
  methods: {
    computeValueFromDate(default_mapped_date_field, default_mapped_date_type, default_mapped_date_custom_range = "") {
      let date = this.form[default_mapped_date_field];
      if (date) {
        let value = this.getValueFromDate(date, default_mapped_date_type, default_mapped_date_custom_range);
        this.$set(this.form, this.data.key, value);
      } else {
        this.$set(this.form, this.data.key, "");
      }
    },
    getValueFromDate(date, type, monthRange = "") {
      const givenDate = new Date(date);
      const dayOfWeek = givenDate.getDay();
      const dayOfMonth = givenDate.getDate();
      const month = givenDate.getMonth();
      const year = givenDate.getFullYear();

      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const dayOfYear = Math.floor(
        (givenDate - new Date(year, 0, 1)) / (1000 * 60 * 60 * 24) + 1
      );

      const isLeapYear =
        year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
      const totalDaysInYear = isLeapYear ? 366 : 365;

      const remainingDaysInYear = totalDaysInYear - dayOfYear;

      const getDaysWithMonths = (month, months = 3) => {
        const quarter = Math.floor(month / months);
        const quarterEndMonth = (quarter + 1) * months;
        const quarterEndDate = new Date(year, quarterEndMonth, 0);
        return Math.floor(
          (quarterEndDate - givenDate) / (1000 * 60 * 60 * 24)
        );
      };

      const getDaysInHalfYear = (month) => {
        const halfYearEndMonth = month < 6 ? 6 : 12;
        const halfYearEndDate = new Date(year, halfYearEndMonth, 0);
        return Math.floor(
          (halfYearEndDate - givenDate) / (1000 * 60 * 60 * 24)
        );
      };

      const daysToEndOfMonth = (date, monthsToAdd) => {
        let startDate = new Date(date);
        startDate.setMonth(startDate.getMonth() + Number(monthsToAdd));
        let endOfMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );
        let timeDifference = endOfMonth - date;

        let daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference;
      };

      const getDaysToNextWeekend = () => {
        const dayOfWeek = givenDate.getDay();
        const daysToSaturday = (6 - dayOfWeek + 7) % 7;
        const daysToSunday = (7 - dayOfWeek) % 7;

        const daysToNextWeekend = daysToSaturday > 0 ? daysToSaturday : daysToSunday;

        return daysToNextWeekend + 7;
      };

      const getRemainingHalfMonthDays = () => {
        const halfMonthEnd = dayOfMonth <= 15 ? 15 : daysInMonth;
        return halfMonthEnd - dayOfMonth;
      };

      switch (type) {
        case "WEEK_DAY":
          return dayOfWeek + 1;

        case "CR_WEEK_DAYS":
          return 7 - (dayOfWeek + 1);

        case "MONTH_DAY":
          return dayOfMonth;

        case "CR_MONTH_DAYS":
          return daysInMonth - dayOfMonth;

        case "C_MONTH_DAYS":
          return daysInMonth;

        case "CR_YEAR_DAYS":
          return remainingDaysInYear;

        case "CR_QUARTER_DAYS":
          return getDaysWithMonths(month);

        case "CR_HALF_YEAR_DAYS":
          return getDaysInHalfYear(month);

        case "CR_CUSTOM_DAYS":
          return daysToEndOfMonth(date, monthRange);

        case "CR_BIWEEK_DAYS":
          return getDaysToNextWeekend();

        case "CR_HALF_MONTH_DAYS":
          return getRemainingHalfMonthDays();
        default:
          return "";
      }
    },
    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        this.checkNumberIsEmpty(this.form[this.data.key], this.data)
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      } else if (
        this.checkNumberIsEmpty(this.form[this.data.key], this.data) &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
      if (this.data.default_mapped_field || this.isDefaultMappedFieldParent) {
        this.$emit("applyDefaultValues", this.allFieldsData, this.form, false);
      }
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (
          globalVariable.inputType == "NUMBER" ||
          globalVariable.input_type == "NUMBER"
        ) {
          this.isList = true;
          this.form[this.data.key] = globalVariable.value;
        }
      }
    },
    //
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
      return (
        this.data.default_mapped_date_field &&
        this.data.default_mapped_date_type
      );
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    validatePhone() {
      if (templateConstants.phonePattern.test(this.form[this.data.key])) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Phone Format";
        this.hasError = true;
      }
    },
    validateZipCode() {
      let fieldValue = this.form[this.data.key];
      if (templateConstants.zipCodePattern.test(fieldValue)) {
        this.errorMsg = "Valid Code";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Code";
        this.hasError = true;
      }
    },
    validateCurrency() {
      let fieldValue = parseFloat(this.form[this.data.key]);
      let minValue = parseFloat(this.data.min_value);
      let maxValue = parseFloat(this.data.max_value);
      if (templateConstants.currencyPattern.test(this.form[this.data.key])) {
        if (
          this.data.max_value &&
          this.data.min_value &&
          this.data.variable_action != "SUM"
        ) {
          if (this.checkInBetween(fieldValue, maxValue, minValue)) {
            this.errorMsg = "";
            this.hasError = false;
          } else {
            this.errorMsg =
              "Value should range between " +
              this.data.max_value +
              " and " +
              this.data.min_value;
            this.hasError = true;
          }
        } else {
          this.errorMsg = "";
          this.hasError = false;
        }
      } else {
        // this.errorMsg = 'Accepts non-negative numbers with max two decimals.';
        this.hasError = true;
      }
    },

    validateDecimal() {
      let fieldValue = parseFloat(this.form[this.data.key]);
      let minValue = parseFloat(this.data.min_value);
      let maxValue = parseFloat(this.data.max_value);
      if (templateConstants.decimalPattern.test(this.form[this.data.key])) {
        if (this.data.max_value && this.data.min_value) {
          if (this.checkInBetween(fieldValue, maxValue, minValue)) {
            this.errorMsg = "";
            this.hasError = false;
          } else {
            this.errorMsg =
              "Value should range between " +
              this.data.max_value +
              " and " +
              this.data.min_value;
            this.hasError = true;
          }
        } else {
          this.errorMsg = "";
          this.hasError = false;
        }
      } else {
        // this.errorMsg = 'Accepts positive and negative numbers with max two decimals.';
        this.hasError = true;
      }
    },
    validateFax() {
      if (templateConstants.faxPattern.test(this.form[this.data.key])) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Fax Format";
        this.hasError = true;
      }
    },
    handleEnterNumber(data) {
      console.log(data);
      // Get the input value and remove non-numeric characters
      const inputValue = event.target.value.replace(/[^0-9-]/g, "");

      // Update the data property with the sanitized input
      this.form[this.data.key] = inputValue;
    },
    validateNumber() {
      let inputValue = this.form[this.data.key];

      if (
        inputValue &&
        typeof inputValue == "string" &&
        inputValue.trim() === ""
      ) {
        // No input provided, clear error message
        this.errorMsg = "";
        this.hasError = false;
        return;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.number_type &&
        this.data.properties.number_type === "DECIMAL"
      ) {
        let fieldValue = parseFloat(this.form[this.data.key]);
        if (typeof fieldValue == "number") {
          this.$set(this.form, this.data.key, fieldValue);
        } else {
          this.form[this.data.key] = "";
        }

        let minValue = parseFloat(this.data.min_value);
        let maxValue = parseFloat(this.data.max_value);
        if (templateConstants.decimalPattern.test(this.form[this.data.key])) {
          if (
            this.data.max_value &&
            this.data.min_value &&
            this.data?.variable_action != "SUM"
          ) {
            if (this.checkInBetween(fieldValue, maxValue, minValue)) {
              this.errorMsg = "";
              this.hasError = false;
            } else {
              this.errorMsg =
                "Value should range between " +
                this.data.max_value +
                " and " +
                this.data.min_value;
              this.hasError = true;
            }
          } else {
            this.errorMsg = "";
            this.hasError = false;
          }
        } else {
          // this.errorMsg = 'Accepts positive and negative numbers with max two decimals.';
          this.hasError = true;
        }
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.number_type &&
        this.data.properties.number_type === "PERCENTAGE"
      ) {
        this.inputType = "text";
        let fieldValue = parseFloat(this.form[this.data.key]);
        if (typeof fieldValue == "number") {
          this.$set(this.form, this.data.key, fieldValue);
        } else {
          this.form[this.data.key] = "";
        }

        let minValue = parseFloat(this.data.min_value);
        let maxValue = parseFloat(this.data.max_value);
        if (
          templateConstants.percentagePattern.test(this.form[this.data.key])
        ) {
          if (
            this.data.max_value &&
            this.data.min_value &&
            this.data?.variable_action != "SUM"
          ) {
            if (this.checkInBetween(fieldValue, maxValue, minValue)) {
              this.errorMsg = "";
              this.hasError = false;
            } else {
              this.errorMsg =
                "Value should range between " +
                this.data.max_value +
                " and " +
                this.data.min_value;
              this.hasError = true;
            }
          } else {
            this.errorMsg = "";
            this.hasError = false;
          }
          this.form[this.data.key] = this.form[this.data.key].toString() + "%";
          // this.form[this.data.key] = this.form[this.data.key]+ '%';
        } else {
          // this.errorMsg = 'Accepts positive and negative numbers with max two decimals.';
          this.hasError = true;
        }
      } else {
        let fieldValue = this.form[this.data.key];
        if (this.data.key === "zip_code") {
          if (!this.validateZipCode(fieldValue)) {
            return;
          }
        }
        fieldValue = parseInt(this.form[this.data.key]);
        if (typeof fieldValue == "number") {
          this.$set(this.form, this.data.key, fieldValue);
        } else {
          this.form[this.data.key] = "";
        }
        let minValue = parseInt(this.data.min_value);
        let maxValue = parseInt(this.data.max_value);
        if (templateConstants.numberPattern.test(this.form[this.data.key])) {
          if (
            ((this.data.max_value !== null &&
              this.data.max_value !== undefined) ||
              (this.data.min_value !== null &&
                this.data.min_value !== undefined)) &&
            this.data?.variable_action != "SUM"
          ) {
            if (this.checkInBetween(fieldValue, maxValue, minValue)) {
              this.errorMsg = "";
              this.hasError = false;
            } else if (
              this.data.max_value !== null &&
              this.data.max_value !== undefined &&
              this.data.min_value !== null &&
              this.data.min_value !== undefined
            ) {
              this.errorMsg =
                "Value should range between " +
                Math.min(this.data.min_value, this.data.max_value) +
                " and " +
                Math.max(this.data.min_value, this.data.max_value);
                if (minValue === maxValue) {
                fieldValue = fieldValue.toString();
                if (fieldValue.length === minValue) {
                  this.errorMsg = "";
                  this.hasError = false;
                } else {
                  this.errorMsg = "You can only enter " + this.data.min_value + " digits";
                  this.hasError = true;
                  this.form[this.data.key] = "";
                }
              }
            } else if (fieldValue > maxValue) {
              this.errorMsg = "Value is greater than " + this.data.max_value;
              this.hasError = true;
              this.form[this.data.key] = "";
            } else if (fieldValue < minValue) {
              this.errorMsg = "Value is less than " + this.data.min_value;
              this.hasError = true;
              this.form[this.data.key] = "";
            } else {
              this.errorMsg = "";
              this.hasError = false;
            }
          } else {
            this.errorMsg = "";
            this.hasError = false;
          }
        } else {
          this.errorMsg = "Please enter a valid number";

          this.hasError = true;
        }
      }
    },
    checkInBetween(fieldValue, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldValue.between(minValue, maxValue);
    },
  },
  watch: {
    form: {
      async handler() {
        if (
          this.data.default_mapped_date_field &&
          this.data.default_mapped_date_type &&
          this.previousValue !== this.form[this.data.default_mapped_date_field]
        ) {
          this.previousValue = this.form[this.data.default_mapped_date_field];
          this.computeValueFromDate(
            this.data.default_mapped_date_field,
            this.data.default_mapped_date_type,
            this.data.default_mapped_date_custom_range
          );
        }
      },
      deep: true,
    },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss">
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.percentag_style {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
}
.percentag_style:focus {
  border-color: #648eee;
}
</style>

<style lang="scss" scoped>
.highlighted-border {
  border-color: red !important;
}
.nfe {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  @media (max-width: 760px) {
    width: 100% !important;
  }
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
  @media only screen and (max-width: 767px) {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: 16px !important;
    height: 32px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
}

.disabled-field {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  pointer-events: painted;
  cursor: not-allowed;
}
.execute-view {
  font-weight: normal;
}
</style>
